import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { ProductsSection } from '../pageSections/ProductsSection';
import { CountriesAllProductsSection } from '../pageSections/CountriesAllProductsSection';
import { ProductsCtaSection } from '../pageSections/ProductsCtaSection';
import { OurSolutionsSection } from '../pageSections/OurSolutionsSection';
import { MetricsSectionIncreaseTheme } from '../pageSections/MetricsSectionIncreaseTheme';
import { Layout } from '../layout/Layout';

const sectionMapping = {
  Strapi_ComponentPageSectionsProductsSection: ProductsSection,
  Strapi_ComponentPageSectionsMetricsSection: MetricsSectionIncreaseTheme,
  Strapi_ComponentPageSectionsOurSolutionsSection: OurSolutionsSection,
  Strapi_ComponentPageSectionsProductsCtaSection: ProductsCtaSection,
};

export default ({ data }) => {
  const { seoInfo, sections } = data.strapi.productsHomePage;
  return (
    <Layout variant="increase">
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section
            backgroundVariant="productos"
            mapping={sectionMapping}
            key={`${s.type}${s.id}`}
            variant="increase"
            {...s}
          />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  fragment BigImage on Strapi_UploadFile {
    url
    imageSharp {
      childImageSharp {
        fluid(maxWidth: 900, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }

  query {
    strapi {
      productsHomePage {
        seoInfo {
          pageDescription
          pageTitle
          keywords
        }
        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsProductsSection {
            id
            title
            description
          }

          ... on Strapi_ComponentPageSectionsOurSolutionsSection {
            id
            title
            description
            solutions {
              id
              image {
                ...Image
              }
              icon {
                url
                imageSharp {
                  publicURL
                }
              }
              name
              description
            }
          }

          ... on Strapi_ComponentPageSectionsMetricsSection {
            id
            metrics: metric {
              id
              name
              type
              gaugeText
              value: content
              valueType
              desktopColumns
            }
          }

          ... on Strapi_ComponentPageSectionsProductsCtaSection {
            id
            title
            subtitle
            primaryCTA {
              ...CTA
            }
            secondaryCTA {
              ...CTA
            }
          }
        }
      }
    }
  }
`;
